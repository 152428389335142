.main-footer {
  background-color: $bg-color;
  &__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
  }
  @include tablets {
    &__layout {
      flex-direction: column;
    }
    &__social {
      margin-top: 15px;
    }
  }
}

.copy {
  font-size: 16px;
  &__rights {
    margin-bottom: 10px;
  }
  @include tablets {
    font-size: 15px;
    text-align: center;
  }
}