.contacts {
  display: flex;
  &__map {
    flex: 1;
  }
  &__content {
    flex: 1;
    &-inner {
      max-width: 510px;
      padding: 30px 20px 30px 30px;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__address {
    background-color: $bg-color;
    border-radius: $border-radius-base;
    font-size: 18px;
    padding: 5px 10px;
    text-align: center;
  }
  @include tablets {
    flex-direction: column;
    &__map {
      height: 400px;
    }
    &__content {
      &-inner {
        max-width: 100%;
        padding: 20px 15px;
      }
    }
  }
  @include phones {
    &__map {
      height: 200px;
    }
  }
}

.popup-map {
  height: 400px;
  @include phones {
    height: 300px;
  }
}