.gallery {
  margin: 0 auto 40px;
  padding-bottom: 40px;
  position: relative;
  &:before {
    background: url("/skin/images/blue_pattern.png") 0 0 repeat $primary-color;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: -2000px;
    right: -2000px;
  }
  &__frame {
    overflow: hidden;
    position: relative;
  }
  &__slidee {
    display: flex;
    align-items: center;
  }
  &__item {
    flex: none;
    margin-right: 16px;
    text-align: center;
    width: 482px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__ttl {
    color: $light-color;
    font-size: 23px;
    padding: 20px 0;
  }
  &__images {
    background-color: $light-color;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    &-ttl {
      font-size: 18px;
      font-weight: bold;
      padding: 5px 0;
    }
    &-col {
      margin-left: 4px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__photos {
    &-item {
      margin-bottom: 5px;
    }
  }
  &__nav {
    background: transparent;
    border: none;
    color: $light-color;
    cursor: pointer;
    height: 66px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 0;
    width: 30px;
    &:hover {
      color: $primary-color-lightest;
    }
    &-arrow {
      font-size: 33px;
      transition: all .2s ease;
    }
    &_prev {
      left: 0;
      .gallery__nav-arrow {
        transform: rotate(180deg);
      }
    }
    &_next {
      right: 0;
    }
  }
  @include media(1040px) {
    max-width: 932px;
    &__item {
      width: 458px;
    }
  }
  @include laptop {
    max-width: 700px;
    &__item {
      width: 342px;
    }
  }
  @include tablets {
    max-width: 550px;
    &__item {
      width: 267px;
    }
  }
  @include media(600px) {
    max-width: 430px;
    &__item {
      width: 430px;
    }
  }
  @include phones {
    max-width: 290px;
    &__item {
      width: 290px;
    }
  }
}