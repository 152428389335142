.ways {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	&__item {
		width: 100%;
	}
	&__content {
		padding: 10px 10px;
		text-align: center; 
	}
	&__map {
		width: 100%;
		height: 300px; 
	}
	@include tablets {
	-ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;	
	}
}  