.reviews {
  &__item {
    background-color: rgba($bg-color, .6);
    border-radius: $border-radius-base;
    margin-bottom: 20px;
    padding: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__name {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.add-review {
  float: right;
  margin-top: -62px;
  @include phones {
    margin-top: -50px;
  }
}