.service-menu {
  background-color: $bg-color;
  border-top: 1px solid $base-color-lightest;
  border-bottom: 1px solid $base-color-lightest;
  &__layout {
    padding: 25px 0 25px 100px;
    position: relative;
  }
  &__img {
  
	@include phones {
		& {
		display: none;
		}  
	}
    @extend %centered-content;
    background-color: $light-color;
    border-radius: $border-radius-base;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 60px;
  }
  &__pretext{
    text-transform: uppercase;
	height: 50px;
	top: 50%;
	font-size: 25px;
	color: $primary-color;
  }
  &__sections {
    margin-left: -30px;
    /*margin-bottom: 5px;*/
    &-item {
      display: inline-block;
      /*font-size: 16px;*/
      line-height: 1.6;
      margin-left: 10px;
      vertical-align: middle;
      /*text-transform: uppercase;*/
    }
  }
  &__models {
    margin-left: -30px;
    &-item {
      color: $text-color;
      display: inline-block;
      line-height: 1.6;
      margin-left: 10px;
      vertical-align: middle;
    }
    &-link {
      color: $text-color;
      &.active {
        background-color: $light-color;
        border-radius: $border-radius-base;
        text-decoration: none;
        color: $text-color-light;
        padding: 0 15px;
      }
    }
  }
  @include tablets {
    &__sections {
      margin-left: -15px;
      &-item {
        margin-left: 15px;
        font-size: 14px;
      }
    }
    &__models {
      margin-left: -10px;
      &-item {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
  @include phones {
    &__layout {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px 0;
    }
    &__img {
      flex: none;
      margin-right: 20px;
      position: static;
      transform: none;
    }
    &__sections {
      margin-bottom: 0;
      &-item {
        display: block;
        font-size: 14px;
      }
    }
    &__models {
      margin-top: 5px;
      margin-left: -10px;
      width: 100%;
      &-item {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}