%btn {
  border: none;
  border-radius: $border-radius-base;
  display: inline-block;
  font-family: $font-family-heading;
  font-size: $btn-font-size;
  font-weight: bold;
  height: $btn-height;
  line-height: $btn-height;
  outline: none;
  padding: $btn-padding;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .2s ease;
  vertical-align: middle;
  &:not(.no-ac) {
    cursor: pointer;
  }
}

%form-element {
  background-color: $input-bg-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $border-radius-base;
  color: $text-color;
  display: inline-block;
  height: $input-height;
  outline: none;
  padding: $input-padding;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  appearance: none;
  &:focus {
    border-color: $primary-color;
  }
  @include phones {
    height: 40px;
  }
}

%ttl-base {
  color: $primary-color;
  font-family: $font-family-heading;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: .8em;
  .content & {
    margin-bottom: .8em;
  }
  @include tablets {
    font-size: 28px;
  }
  @include phones {
    font-size: 22px;
  }
}

%ttl-base-s {
  color: $primary-color;
  font-family: $font-family-heading;
  font-size: 23px;
  font-weight: normal;
  margin-bottom: .8em;
  .content & {
    margin-bottom: .8em;
  }
  @include tablets {
    font-size: 22px;
  }
  @include phones {
    font-size: 20px;
  }
}


%ttl-base-ss {
  color: $primary-color;
  font-family: $font-family-heading;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: .8em;
  .content & {
    margin-bottom: .8em;
  }
  @include tablets {
    font-size: 22px;
  }
  @include phones {
    font-size: 20px;
  }
}

%clearfix {
  &:before,
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

%no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

%justify-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

%centered-self {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}