.action {
  background: url("/skin/images/action_bg.jpg") 50% 50% no-repeat;
  background-size: cover;
  //height: 360px;
  height: 130px;
  &__layout {
    position: relative;
    margin-left: 68px;
  }
  &__block {
    color: $light-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    position: absolute;
    width: 120px;
    &:before {
      border-radius: $border-radius-base;
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate(45deg);
      width: 100%;
    }
    &-text {
      margin: 0 -10px;
      position: relative;
      text-align: center;
      z-index: 1;
      &_m {
        font-size: 22px;
      }
      &_l {
        font-size: 34px;
        font-weight: bold;
      }
    }
    &_first {
      font-size: 24px;
      height: 148px;
      top: 0;
      left: 0;
      width: 148px;
      &:before {
        background-color: $primary-color;
      }
    }
    &_second {
      top: 124px;
      left: 106px;
      &:before {
        background-color: $secondary-color;
      }
    }
    &_third {
      font-size: 14px;
      top: 214px;
      left: 14px;
      &:before {
        background-color: $primary-color-light;
      }
    }
  }
  &__link {
    border-bottom: 2px dotted rgba($light-color, .7);
    color: $light-color;
    text-decoration: none;
    &:hover {
      border-color: transparent;
    }
  }
  @include laptop {
    height: 250px;
    &__block {
      font-size: 14px;
      height: 84px;
      width: 84px;
      &-text {
        &_m {
          font-size: 16px;
        }
        &_l {
          font-size: 20px;
        }
      }
      &_first {
        font-size: 18px;
        height: 104px;
        width: 104px;
      }
      &_second {
        top: 84px;
        left: 78px;
      }
      &_third {
        font-size: 10px;
        top: 144px;
        left: 10px;
      }
    }
  }
  @include tablets {
    &__layout {
      margin-left: 20px;
    }
  }
}