.popup {
  display: none;
}

#MyCMSDialogShadow {
  background-color: rgba(#000, 0.6);
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.mycms-dialog {
  animation-duration: .3s;
  padding: 0 10px 10px;
  position: absolute;
  text-align: center;
  min-width: 320px;
  width: 100%;
  iframe {
    border: none;
  }
  &__container {
    background-color: $light-color;
    border-radius: $border-radius-base;
    display: inline-block;
    vertical-align: top;
    padding: 30px 40px 40px;
    position: relative;
    margin: 0 auto;
    text-align: left;
    max-width: 100%;
    width: 700px;
  }
  &__title {
    color: $primary-color;
    font-size: 23px;
    margin-bottom: .8em;
    text-align: center;
    text-transform: uppercase;
  }
  &__close {
    @include close-btn($primary-color-light, 3px, 31px, $light-color);
    position: absolute;
    right: -15px;
    top: -15px;
  }
  &__content {

  }
  @include laptop {
    &__container {
      padding: 20px;
    }
    &__close {
      right: -8px;
    }
  }
  @include phones {
    &__container {
      padding: 15px;
    }
    &__title {
      font-size: 22px;
      margin-bottom: .6em;
    }
  }
  .striped-tbl {
    background-color: $light-color;
  }
  ul {
    li {
      &:before {
        border: 3px solid $bg-color;
      }
    }
  }
}

.ok-message {
  font-size: 18px;
  padding: 20px 0;
  text-align: center;
}