.advantages {
  display: flex;
  margin-left: -30px;
  &__item {
    flex: 1;
    margin-left: 30px;
  }
  &__img {
    margin: 0 auto 15px;
    position: relative;
    width: 200px;
    &-mark {
      background-color: $bg-color;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      border-radius: 50%;
      height: 60px;
      position: absolute;
      top: 0;
      //right: -15px;
	  right: 15px;
      width: 60px;
      &_check {
        background-image: url("/skin/images/png-sprite/check.png");
      }
      &_not {
        background-image: url("/skin/images/png-sprite/not.png");
      }
    }
    &-mask {
      border-radius: 50%;
      //height: 200px;
      overflow: hidden;
      //width: 200px;
	  width: 150px;
	  height: 150px;
    }
  }
  &__ttl {
    color: $primary-color;
    font-size: 23px;
    margin-bottom: 15px;
    text-align: center;
  }
  &__text {
    text-align: justify;
  }
  @include tablets {
    flex-direction: column;
    &__item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include phones {
    &__img {
      margin-bottom: 10px;
      width: 140px;
      &-mark {
        height: 40px;
        top: 5px;
        right: -10px;
        width: 40px;
      }
      &-mask {
        height: 140px;
        width: 140px;
      }
    }
    &__ttl {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
}