@font-face {
  font-family: 'PTSans';
  src: url('/skin/fonts/PTSans/PTSans-Regular/PTSans-Regular.eot');
  src: url('/skin/fonts/PTSans/PTSans-Regular/PTSans-Regular.woff2') format('woff2'),
  url('/skin/fonts/PTSans/PTSans-Regular/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('/skin/fonts/PTSans/PTSans-Regular/PTSans-Regular.woff') format('woff'),
  url('/skin/fonts/PTSans/PTSans-Regular/PTSans-Regular.ttf') format('truetype'),
  url('/skin/fonts/PTSans/PTSans-Regular/PTSans-Regular.svg#PTSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PTSans';
  src: url('/skin/fonts/PTSans/PTSans-Bold/PTSans-Bold.eot');
  src: url('/skin/fonts/PTSans/PTSans-Bold/PTSans-Bold.woff2') format('woff2'),
  url('/skin/fonts/PTSans/PTSans-Bold/PTSansBold.eot?#iefix') format('embedded-opentype'),
  url('/skin/fonts/PTSans/PTSans-Bold/PTSans-Bold.woff') format('woff'),
  url('/skin/fonts/PTSans/PTSans-Bold/PTSans-Bold.ttf') format('truetype'),
  url('/skin/fonts/PTSans/PTSans-Bold/PTSans-Bold.svg#PTSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PTSans';
  src: url('/skin/fonts/PTSans/PTSans-Italic/PTSans-Italic.eot');
  src: url('/skin/fonts/PTSans/PTSans-Italic/PTSans-Italic.woff2') format('woff2'),
  url('/skin/fonts/PTSans/PTSans-Italic/PTSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('/skin/fonts/PTSans/PTSans-Italic/PTSans-Italic.woff') format('woff'),
  url('/skin/fonts/PTSans/PTSans-Italic/PTSans-Italic.ttf') format('truetype'),
  url('/skin/fonts/PTSans/PTSans-Italic/PTSans-Italic.svg#PTSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
