.btn {
  @extend %btn;
  &_primary {
    color: $light-color;
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color-light;
    }
  }
  &_primary-gradient {
    color: $light-color;
    overflow: hidden;
    position: relative;
    span {
      position: relative;
      z-index: 1;
    }
    &:before {
      background-color: $primary-color;
      content: '';
      height: 50%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      transition: all .2s ease;
    }
    &:after {
      background-color: $primary-color-light;
      content: '';
      height: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      transition: all .2s ease;
    }
    &:hover {
      &:before {
        background-color: $primary-color-dark;
        height: 100%;
      }
      &:after {
        background-color: $primary-color;
        top: 100%;
      }
    }
  }
  &_primary-light {
    color: $light-color;
    background-color: $primary-color-light;
    &:hover {
      background-color: $primary-color-lighter;
    }
  }
  &_primary-lightest {
    color: $primary-color;
    background-color: $primary-color-lightest;
    &:not(.no-ac) {
      &:hover {
        background-color: $light-color;
      }
    }
  }
}