.service-page{
	
	&__img{
		background: $bg-color;
		text-align:center;
	}
	&__text{
		margin-top: 10px;
		text-align: justify;
	}
}