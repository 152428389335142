.special{
	text-align: justify;
	font-size: 20px;
	font-weight: bold;
	margin: 10px auto;
	padding: 10px;
	color: #508bb6;
	padding: 30px;
	text-align: center;
	border: 1px dotted #CBCBCB;
	border-radius: 10px;	
}

.special2{
	border: 1px solid #CBCBCB;
	border-radius: 10px;
	margin: 10px auto;
	padding: 10px;
	& a{
		text-transform: uppercase;
	}
}

.content_img{
	text-align: center;
	margin: 10px 0;
}