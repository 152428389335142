.icon-arrow {
  font-size:(14/(10*1.7))*1rem;
  width:(8/14)*1em;
}
.icon-phone {
  font-size:(30/(10*1.7))*1rem;
  width:(30/30)*1em;
}
.icon-point {
  font-size:(30/(10*1.7))*1rem;
  width:(30/30)*1em;
}
.icon-search {
  font-size:(30/(10*1.7))*1rem;
  width:(30/30)*1em;
}
