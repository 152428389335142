 .advantages__form {
    margin-top: 30px;
}

.advantages__form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.advantages__form-row .form-base__field {
    width: 32%;
}

.advantages__form .textarea-base {
    height: 80px;
}

.advantages__form .agree {
    border: none;
    font-size: 15px;
    margin-bottom: 30px;
    padding: 0;
}

.advantages__form .agree .switch-lbl {
    padding-left: 0;
}

.advantages__form .form-base__btns {
    text-align: center;
}

@media all and (max-width: 500px) {
    .advantages__form-row {
        display: block;
    }
    .advantages__form-row .form-base__field {
        width: 100%;
    }
}

.content .service-menu__layout ul {
    margin-bottom: 0;
}

.content .service-menu__layout ul li {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10px;
    padding-left: 0;
    position: static;
}

.content .service-menu__layout ul li:before {
    display: none;
}

.brands-list-section .layout-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.brands-list-section .service-menu__sections-link {
    text-decoration: none;
}

.service-menu__col {
    width: 48.5%;
}

.brands-list-section .service-menu__layout {
    border-bottom: 1px solid #cbcbcb;
}

.brands-list-section .service-menu__layout:last-child {
    border-bottom: none;
}

@media all and (max-width: 768px) {
    .brands-list-section .layout-main {
        display: block;
    }
    .service-menu__col {
        width: 100%;
    }
    .brands-list-section .service-menu__layout:last-child {
        border-bottom: 1px solid #cbcbcb;
    }
    .brands-list-section .service-menu__col:last-child .service-menu__layout:last-child {
        border-bottom: none;
    }
}