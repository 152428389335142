.main-body {
  background-color: $body-bg;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  overflow: hidden;
}

.main-center {
  flex: 1 0 auto;
  &__layout {
    padding-top: 30px;
    padding-bottom: 30px;
    @include tablets {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.main-header {

}

.main-footer {

}

.layout-main {
  margin: 0 auto;
  max-width: 1020px;
  padding-left: 20px;
  padding-right: 20px;
  @include tablets {
    padding-left: 15px;
    padding-right: 15px;
  }
}