.main-header {
  &__top {
    &-layout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 940px;
      margin: 0 auto;
      padding: 25px 0 20px;
      @include phones {
        flex-direction: column;
        padding: 20px 0;
      }
    }
  }
  &__nav {
    border-top: 1px solid transparent;
    position: relative;
    &_index {
      border-color: $base-color-lightest;
    }
    &_inner {
      background-color: $primary-color;
      border-color: $light-color;
    }
  }
  @include media(680px) {
    &__nav {
      border-top: none;
      &_index {
        padding-bottom: 30px;
      }
      &_inner {
        padding: 10px 0;
      }
    }
  }
}

.header-logo {
  flex: none;
  margin-right: 14%;
  text-align: center;
	@include phones {
		& {
		text-align: left;
		}  
	}  
  
  &__brand {
	color: #0E8CB9;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
  }
  
  &__link {
    display: block;
    text-decoration: none;
  }
  &__img {
    margin-bottom: 15px;
  }
  &__text {
	@include phones {
		& {
		display: none;
		}  
	}  
    font-size: 15px;
  }
  @include laptop {
    margin-right: 15px;
    width: 34%;
    &__img {
      margin-bottom: 10px;
    }
    &__text {
      font-size: 14px;
    }
  }
  @include phones {
    margin: 0 0 15px;
    width: 100%;
  }
}

.header-contacts {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  &__item {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 18px;
  }
  &__icon {
    flex: none;
    margin-right: 15px;
    text-align: right;
    width: 35px;
  }
  &__phone {
    font-size: 22px;
    &-link {
      color: $text-color;
      text-decoration: none;
      span {
        color: $text-color-light;
      }
    }
  }
  &__link {
	@include phones{
		& {
			display: none;
		}	
	}
    font-size: 15px;
    margin: 5px 0 0 50px;
    a {
      color: $text-color-light;
    }
  }
  @include laptop {
    flex: none;
    &__content {
      font-size: 16px;
    }
    &__phone {
      font-size: 20px;
    }
    &__icon {
      width: 22px;
      &-phone {
        width: 20px;
      }
    }
    &__link {
      margin-left: 37px;
    }
  }
  @include tablets {
    flex-direction: column;
    &__item {
      margin-left: 0;
      margin-bottom: 15px;
      padding-left: 30px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__icon {
      position: absolute;
      left: 0;
      top: 0;
    }
    &__phone {
      display: inline-block;
      font-size: 17px;
	  /*
      &:not(:last-child) {
        &:after {
          content: ';';
        }
      }
	  */

    }
    &__link {
      margin: 0;
    }
  }
}

.header-nav {
  display: flex;
  align-items: center;
  &__menu {

  }
  &__btns {
    display: flex;
    margin-left: 40px;
    .btn {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  .main-header__nav_index & {
    justify-content: flex-end;
  }
  .main-header__nav_inner & {
    justify-content: space-between;
  }
  @include laptop {
    &__btns {
      margin-left: 20px;
      .main-header__nav_inner & {
        .btn {
          padding: 0 10px;
        }
      }
    }
  }
  @include tablets {
    &__btns {
      margin-left: 10px;
      .main-header__nav_inner & {
        .btn {
          padding: 0 8px;
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
      .btn_primary-light {
        font-size: 14px;
      }
      .btn_primary-lightest {
        font-size: 13px;
      }
    }
  }
  @include media(680px) {
    .main-header__nav_index & {
      justify-content: space-between;
    }
    &__menu {
      display: none;
      background-color: $primary-color;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
  @include _media(681px) {
    &__menu {
      display: block !important;
    }
  }
  @include phones {
    &__btns {
      .main-header__nav_index & {
        flex: 1;
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.main-menu {
  display: flex;
  &__item {
    position: relative;
    @include _media(681px) {
      &:hover {
        .main-menu__link {
          background-color: $primary-color-light;
          color: $light-color;
        }
        .main-menu__sub-arrow {
          color: $light-color;
          transform: rotate(-90deg);
        }
        .main-menu__sub {
          left: 0;
          opacity: 1;
        }
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    height: 70px;
    padding: 0 16px;
    text-decoration: none;
    text-transform: uppercase;
    .main-header__nav_index & {
      color: $primary-color;
    }
    .main-header__nav_inner & {
      color: $light-color;
    }
    @include _media(681px) {
      &:hover {
        background-color: $primary-color-light;
        color: $light-color;
      }
    }
  }
  &__sub-arrow {
    margin-left: -7px;
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: rotate(90deg);
    transition: all .2s ease;
    .main-header__nav_index & {
      color: $primary-color;
    }
    .main-header__nav_inner & {
      color: $light-color;
    }
  }
  &__sub {
    background-color: $primary-color-light;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    opacity: 0;
    padding: 30px 20px 0;
    position: absolute;
    left: -9999px;
    transition: opacity .2s ease;
    width: 530px;
    z-index: 2;
  }
  @include laptop {
    &__link {
      font-size: 14px;
      height: 60px;
      padding: 0 10px;
    }
    &__sub {
      padding: 20px 15px 0;
      width: 430px;
    }
  }
  @include media(680px) {
    flex-direction: column;
    padding: 30px 0 10px;
    &__item {
      &.has-sub {
        background-color: $primary-color-light;
        margin: 10px 0;
        .main-menu__link {
          padding: 10px 15px;
        }
      }
    }
    &__link {
      display: block;
      font-size: 20px;
      font-weight: bold;
      height: auto;
      padding: 3px 15px;
      text-align: center;
      .main-header__nav_index & {
        color: $light-color;
      }
    }
    &__sub {
      left: 0;
      opacity: 1;
      padding-top: 0;
      position: relative;
      width: 100%;
    }
  }
}

.main-submenu {
  column-count: 2;
  column-gap: 30px;
  font-size: 16px;
  a {
    color: $light-color;
    text-decoration: none;
    &:hover {
      color: $primary-color-lightest;
    }
  }
  &__item {
    display: inline-block;
    width: 230px;
    //margin-bottom: 20px;
    &-inner {
      padding-bottom: 10px;
    }
  }
  &__ttl {
    display: flex;
    align-items: center;
    font-size: 19px;
    min-height: 35px;
    text-transform: uppercase;
    &-icon {
      align-self: flex-start;
      flex: none;
      margin-right: 5px;
      text-align: center;
      width: 36px;
    }
    &-link {
      line-height: 24px;
	  
	  &-header {
	    color: #FFFFFF;
		font-weight: bold;
	  }
    }
	
  }
  &__ttl-s {
    font-size: 20px;
  }
  &__list {
    &-item {
      margin: 4px 0;
      a:before {
        content: '- ';
      }
    }
  }
  @include laptop {
    column-gap: 10px;
    font-size: 15px;
    &__item {
      margin-bottom: 15px;
    }
    &__ttl {
      font-size: 15px;
      font-weight: bold;
    }
    &__ttl-s {
      font-size: 15px;
    }
  }
  @include media(680px) {
    column-gap: 5px;
  }
}

.menu-btn {
  border: 2px solid transparent;
  border-radius: $border-radius-base;
  display: none;
  height: 38px;
  position: relative;
  z-index: 3;
  width: 40px;
  .main-header__nav_index & {
    color: $light-color;
    overflow: hidden;
    position: relative;
    &:before {
      background-color: $primary-color;
      content: '';
      height: 50%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      transition: all .2s ease;
    }
    &:after {
      background-color: $primary-color-light;
      content: '';
      height: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      transition: all .2s ease;
    }
    &:hover {
      &:before {
        background-color: $primary-color-dark;
        height: 100%;
      }
      &:after {
        background-color: $primary-color;
        top: 100%;
      }
    }
  }
  .main-header__nav_inner & {
    border-color: $primary-color-lightest;
  }
  &__icon {
    background: $light-color;
    border-radius: 2px;
    height: 2px;
    position: absolute;
    top: 16px;
    left: 8px;
    right: 8px;
    transition: background 0 .3s;
    z-index: 1;
    &:before,
    &:after {
      background-color: $light-color;
      content: '';
      height: 2px;
      position: absolute;
      left: 0;
      width: 100%;
      transition-duration: .3s, .3s;
    }
    &:before {
      top: -7px;
      transition-property: top, transform;
    }
    &:after {
      bottom: -7px;
      transition-property: bottom, transform;
    }
  }
  &.is-open {
    .main-header__nav_index & {
      &:before,
      &:after {
        display: none;
      }
    }
    .main-header__nav_inner & {
      border-color: transparent;
    }
    .menu-btn__icon {
      background: none;
      &:before {
        top: 0;
        transform: rotate(45deg);
        transition-delay: 0, .3s;
      }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
        transition-delay: 0, .3s;
      }
    }
  }

  @include media(680px) {
    display: block;
  }
}