.brands {
  &__frame {
    overflow: hidden;
    position: relative;
  }
  &__slidee {
    display: flex;
    align-items: center;
    height: 115px;
  }
  &__item {
    color: $text-color-light;
    flex: none;
    text-align: center;
    width: 100px;
  }
  &__img {
    @extend %centered-content;
    height: 50px;
  }
  &__nav {
    background: rgba($primary-color-light, .7);
    border: none;
    color: $light-color;
    cursor: pointer;
    height: 100%;
    outline: none;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all .2s ease;
    width: 70px;
    &:hover {
      background-color: rgba($primary-color-light, .9);
    }
    &-arrow {
      font-size: 33px;
    }
    &_prev {
      left: 0;
      .brands__nav-arrow {
        transform: rotate(180deg);
      }
    }
    &_next {
      right: 0;
    }
  }

  
  
  .slick-next, .slick-prev {
    background: rgba($primary-color-light, .7);
    border: none;
    color: $light-color;
    cursor: pointer;
    height: 100%;
    margin-top: 0;
    outline: none;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all .2s ease;
    width: 70px;
    &:hover {
      background-color: rgba($primary-color-light, .9);
    }
    &:before {
      background: url("/skin/images/arrow-light.svg") 50% 50% no-repeat;
      background-size: 100% auto;
      content: '';
      height: 33px;
      margin-top: -16px;
      top: 50%;
      width: 19px;
    }
  }
  

  &:hover {
    .slick-next, .slick-prev {
      opacity: 1;
    }
  }
  .slick-prev {
    left: 0;
    &:before {
      left: 50%;
      margin-left: -9px;
    }
  }
  .slick-next {
    right: 0;
    &:before {
      left: 50%;
      margin-left: -9px;
    }
  }

  @include phones {
	& {
	  display: none;
	}
    &__slidee {
      height: 100px;
    }
    &__nav {
      width: 40px;
    }
    .slick-next, .slick-prev {
      width: 40px;
    }
  }
}
/*
.models { 
  &__frame {
    overflow: hidden;
    position: relative;
  }
  &__slidee {
    display: flex;
    align-items: center;
    height: 115px;
  }
  &__item {
    color: $text-color-light;
    flex: none;
    text-align: center;
    width: 100px;
  }
  &__img {
    @extend %centered-content;
    height: 55px;
  }
  &__nav {
    background: rgba($primary-color-light, .7);
    border: none;
    color: $light-color;
    cursor: pointer;
    height: 100%;
    outline: none;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all .2s ease;
    width: 70px;
    &:hover {
      background-color: rgba($primary-color-light, .9);
    }
    &-arrow {
      font-size: 33px;
    }
    &_prev {
      left: 0;
      .brands__nav-arrow {
        transform: rotate(180deg);
      }
    }
    &_next {
      right: 0;
    }
  }
}  
*/
.service-brand,.models{
	display:flex;
	flex-wrap:wrap;
	justify-content: center;

	&__item{
		margin: 15px 45px;
		width: 20%; 
		position: relative;
		justify-content: center;
	}
	
	&__img{
		text-align: center;
	}
	&__img img{
		border-radius: 50%;
	}
	&__name{
		text-align: center;
		margin-top: 15px;
		font-weight: bold;
	}
	
	&__name a:after{
		content: '';
		position: absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
	}
	
	&__price{
		text-align: center;
		margin-top: 10px;
		text-transform: uppercase;
		font-size: 10px;
	}	
	
}
