.clients {
  display: flex;
  justify-content: space-between;
  margin-left: -20px;
  &__col {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    &_img {
      .clients__col-item {
        flex: none;
        text-align: center;
      }
    }
    &-item {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__list {
    &-item {
      margin: 8px 0;
      line-height: 1;
      &:before {
        display: none;
      }
    }
    .content & {
      margin: 0;
      &-item {
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  &__img {
    height: 54px;
    line-height: 54px;
    margin-bottom: 15px;
    white-space: nowrap;
  }
  @include laptop {
    margin-left: -10px;
    &__col {
      margin-left: 10px;
      &-item {
        margin-left: 10px;
      }
    }
  }
  @include  tablets {
    flex-direction: column;
    &__col {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include phones {
    &__col {
      flex-direction: column;
      &-item {
        margin-bottom: 10px;
        margin-left: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &_no-img {
        .clients__col-item {
          margin-bottom: -8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &_img {
        .clients__col-item {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;
          text-align: left;
        }
        .clients__ttl {
          flex: none;
          margin-right: 5px;
          width: 140px;
        }
        .clients__img {
          margin-bottom: 0;
        }
      }
    }
  }
}