.grid {
  $root: &;

  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;

  &_equal {
    flex-wrap: nowrap;
  }

  &__item {
    padding: 0 0 30px 30px;

    #{$root}_equal & {
      flex: 1;
    }

    #{$root}_2 & {
      width: 50%;
    }

    #{$root}_3 & {
      width: (100%/3);
    }

    #{$root}_4 & {
      width: 25%;
    }

  }

  @include tablets {
    &_equal {
      flex-wrap: wrap;
    }

    &__item {
      padding: 0 0 20px 20px;

      #{$root}_equal & {
        flex: none;
        width: 100%;
      }

      #{$root}_4 & {
        width: 50%;
      }

    }
  }

  @include phones {
    &__item {

      #{$root}_2 & {
        width: 100%;
      }

      #{$root}_3 & {
        width: 100%;
      }

      #{$root}_4 & {
        width: 100%;
      }

    }
  }

}