html {
  color: $text-color;
  font-size: $font-size-base;
  font-family: $font-family-base;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  transition: all .2s ease;
  &:hover, &:active {
    text-decoration: $link-hover-decoration;
  }
  .svg-icon {
    transition: fill .2s ease;
  }
}

table {
  border-collapse: collapse;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  @extend %ttl-base;
  margin-top: 0;
  text-align: center;
}

h2 {
  @extend %ttl-base-s;
  margin-top: 0;
  text-align: center;
	background: #eee;
	border: 1px solid #fff;
	border-radius: 10px;
	padding: 10px;  
}

h3 {
  @extend %ttl-base-ss;
  margin-top: 0;
  text-align: center;
}

.content, #tinymce {
  p, ul, ol {
    margin-bottom: 1em;
    margin-top: 0;
	
  }
  
  p{
	line-height: 27px;
  }
  ul {
    list-style: none;
	
    li {
      margin: 5px 0;
      padding-left: 20px;
      position: relative;
      &:before {
        background-color: $primary-color;
        border-radius: 7px;
        content: '';
        height: 7px;
        position: absolute;
        top: 5px;
        left: 0;
        width: 7px;
      }
    }
  }
  ol {
    margin-left: 20px;
    list-style-position: outside;
    list-style-type: decimal;
  }
  table {
    background-color: $bg-color;
    border-bottom: 2px solid rgba($light-color, .5);
    margin-bottom: 1.4em;
    width: 100%;
  }
  th {
    background-color: $primary-color;
    border: none;
    color: $light-color;
    font-weight: normal;
    height: 60px;
    text-align: left;
  }
  td,th {
    padding: 10px 8px;
    &:first-child {
      padding-left: 16px;
    }
  }
  tr {
    &:nth-child(2n) {
      background-color: rgba($light-color, .5);
    }
  }
  .price-tbl {
    tbody {
      border-bottom: 2px solid $base-color-lightest;
    }
    &__ttl {
      font-weight: bold;
      vertical-align: top;
      width: 24%;
    }
  }
}
