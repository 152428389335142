.uppercase { text-transform: uppercase; }
.clear {clear:both;}
.left {float: left;}
.right  {float: right;}


.two-column{
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;	
}

.img-left {
  float: left;
  margin:0 20px 15px 0;
  max-width: 50%;
  @include phones {
    display: block;
    float: none;
    margin: 0 auto 15px;
    max-width: 100%;
  }
}
.img-right  {
  float: right;
  margin:0 0 15px 20px;
  max-width: 50%;
  @include phones {
    display: block;
    float: none;
    margin: 0 auto 15px;
    max-width: 100%;
  }
}
.img-border {border-radius: $border-radius-base}
.t-left {text-align: left !important;}
.t-right  {text-align: right !important;}
.center {text-align: center !important;}
.ofl {overflow: hidden;	_zoom: 1;}
.red {color: $attention-color;}
.orange {color: $primary-color;}
.bold {font-weight: 600;}
.bbold {font-weight: 600; font-size: 16px;}
.hidden {display: none;}
.nowrap {white-space: nowrap;}
.small {font-size: 14px;}

.clearfix {
  @extend %clearfix;
}

.ttl-base {
  @extend %ttl-base;
}

.ttl-base-s {
  @extend %ttl-base-s;
}

.dotted-link {
  border-bottom: 1px dotted;
  text-decoration: none;
  &:hover {
    border-color: transparent;
  }
}

.main-section {
  padding: 30px 0;
  p:last-child {
    margin-bottom: 0;
  }
}

.gray-section {
  background-color: $bg-color;
}

.blue-section {
  background-color: $primary-color-light;
  color: $light-color;
  a {
    color: $light-color;
  }
}

.index-price-tbl {
  td {
    &:nth-child(n+4) {
      text-align: center;
    }
  }
}

.price-tbl {
  td {
    vertical-align: top;
    &:first-child {
      width: 40px;
    }
	&:not(last-child) {
	  border-right: 1px solid #FFFFFF;
	}
  }
  th {
	text-align: center;
	&:last-child {
		width: 20%;
	}
	&:not(last-child) {
	  border-right: 1px solid #FFFFFF;
	}	
	a {
		color: $light-color;
	}
  }
}

.important-note {
  max-width: 650px;
  margin: 0 auto;
  padding-left: 60px;
  position: relative;
  &:before {
    @include sprite($attention);
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @include phones {
    padding-left: 50px;
  }
}

.table-container {
  overflow: auto;
  //-webkit-overflow-scrolling: touch;
  margin: 1.6em 0;
  @include tablets {
    margin: 1.2em 0;
  }
  table {
    margin: 0;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:horizontal {
    background-color: $base-color-lightest;
    border-radius: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid $base-color-lightest;
    background-color: $base-color-light;
    border-radius: 5px;
  }
}

.agree {
    border-radius: 10px;
	border: 2px solid #246EA4;
    font-style: italic;
    margin-bottom: 15px;
    padding: 10px;
    .switch-lbl {
        display: block;
        position: relative;
        padding-left: 30px;
    }
    .switch {
        position: absolute;
        left: 0;
        top: -2px;
    }
}