.attention-section {
  background: url("/skin/images/red_pattern.png") 0 0 repeat;
  color: $light-color;
}
.margin-bottom_10{
	margin-bottom: 10px;
}


.attention-note {
  display: flex;
  align-items: center;
  &__ttl {
    font-size: 38px;
    flex: none;
    margin-right: 40px;
    text-align: center;
    width: 220px;
  }
  @include tablets {
    flex-direction: column;
    &__ttl {
      font-size: 22px;
      margin: 0 0 10px;
      width: 100%;
    }
  }
}