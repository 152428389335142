.speciality {
  display: flex;
  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    &:first-child {
      align-items: flex-end;
    }
  }
  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 250px;
    width: 100%;
    &_first {
      background-image: url("/skin/images/speciality_1.jpg");
      border-right: 1px solid $light-color;
    }
    &_second {
      background-image: url("/skin/images/speciality_2.jpg");
    }
  }
  &__content {
    padding: 30px 15px;
    max-width: 505px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  &__brand {
    display: inline-block;
    min-width: 100px;
    margin-right: 10px;
    vertical-align: middle;
  }
  @include tablets {
    flex-direction: column;
    &__img {
      height: 170px;
      &_first {
        background-image: url("/skin/images/speciality_1.jpg");
        border-right: none;
      }
    }
    &__content {
      padding: 15px 15px 20px;
      max-width: 100%;
    }
  }
}