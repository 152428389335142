.services-section {
  background-color: $primary-color;
  color: $light-color;
  &__layout {
    display: flex;
    height: 250px;
  }
  &__ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    padding-left: 30px;
    position: relative;
    width: 280px;
    z-index: 2;
    &-inner {
	  font-size: 25px;
	  font-weight: normal;
      position: relative;
	  margin: 0;
    }
    &:before {
      background: url("/skin/images/services_bg.png") 100% 50%;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: -2000px;
    }
    &:after {
      background: url("/skin/images/services_arr_bg.png") 0 50%;
      background-size: cover;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      width: 70px;
    }
  }
  &__list {
    display: flex;
    align-items: center;
  }
  @include laptop {
    &__ttl {
      padding-left: 20px;
      width: 160px;
      &-inner {
        font-size: 22px;
      }
    }
  }
  @include tablets {
    &__layout {
      flex-direction: column;
      height: auto;
    }
    &__ttl {
      margin-right: 0;
      padding: 20px;
      text-align: center;
      width: 100%;
    }
  }
  @include phones {
    &__ttl {
      &-inner {
        font-size: 20px;
      }
    }
  }
}

.services {
  display: flex;
  &__col {
    flex: 1;
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
  }
  a {
    color: $light-color;
    text-decoration: none;
    &:hover {
      color: $primary-color-lightest;
    }
  }
  &__item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__ttl {
    font-size: 19px;
    text-transform: uppercase;
  }
  &__ttl-s {
    font-size: 20px;
  }
  &__list {
    .content & {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &-item {
      margin: 4px 0;
      padding: 0;
      a:before {
        content: '- ';
      }
    }
  }
  @include laptop {
    &__col {
      margin-left: 10px;
    }
  }
  @include tablets {
    padding: 20px 0;
    &__col {
      margin-left: 15px;
    }
  }
  @include phones {
    flex-direction: column;
    &__item {
      margin-bottom: 15px;
    }
    &__ttl {
      font-size: 18px;
    }
    &__col {
      margin: 0 0 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.services-images {
  padding: 10px 0;
  &__frame {
    overflow: hidden;
    position: relative;
  }
  &__slidee {
    overflow: hidden;
    height: 230px;
    padding-left: 80px;
  }
  &__item {
    border-radius: $border-radius-base;
    float: left;
    overflow: hidden;
    margin: 0 5px;
    width: 160px;
  }
  @include tablets {
    padding: 10px;
    &__slidee {
      padding-left: 0;
    }
  }
}